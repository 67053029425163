.aui-layout-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.aui-layout-header {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 2rem;
}

.aui-layout-footer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2;
    padding: 1rem;
}

.carousel {
    width: '200px';
    overflow: hidden;
}
/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: #f5f5f5; /* Light mode background */
    --foreground: #222; /* Light mode text color */

    /* Primary color scale (light mode) */
    --primary-100: #060607;
    --primary-200: #0b0b0f;
    --primary-300: #111116;
    --primary-400: #16161e;
    --primary-500: #1c1c25;
    --primary-600: #494951;
    --primary-700: #77777c;
    --primary-800: #a4a4a8;
    --primary-900: #d2d2d3;

    /* Secondary color scale (light mode) */
    --secondary-100: #1d1229;
    --secondary-200: #392452;
    --secondary-300: #56377a;
    --secondary-400: #7249a3;
    --secondary-500: #8f5bcc;
    --secondary-600: #a57cd6;
    --secondary-700: #bc9de0;
    --secondary-800: #d2bdeb;
    --secondary-900: #e9def5;

    --border: #e0e0e0;
    --radius: 0.5rem;
  }

  .dark {
    --background: #1c1c25;
    --foreground: #f5f5f5;

    --primary-100: #d2d2d3;
    --primary-200: #a4a4a8;
    --primary-300: #77777c;
    --primary-400: #494951;
    --primary-500: #1c1c25;
    --primary-600: #16161e;
    --primary-700: #111116;
    --primary-800: #0b0b0f;
    --primary-900: #060607;

    --secondary-100: #e9def5;
    --secondary-200: #d2bdeb;
    --secondary-300: #bc9de0;
    --secondary-400: #a57cd6;
    --secondary-500: #8f5bcc;
    --secondary-600: #7249a3;
    --secondary-700: #56377a;
    --secondary-800: #392452;
    --secondary-900: #1d1229;

    --border: #2b2b35;
    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

html,
body,
#root,
.app,
.content {
  display: flex;
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  font-family: "Inter", "Poppins", sans-serif;
}

.content {
  flex-direction: column;
}

.app {
  position: relative;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
